import { getDownloadURL, listAll, ref } from "firebase/storage";
import { storage } from "./firebase";

export const fetchImages = async (folder: string) => {
	const imageListRef = ref(storage, folder + "/");

	try {
		const res = await listAll(imageListRef);
		const urls = await Promise.all(res.items.map(item => getDownloadURL(item)));
		return urls;
	} catch (e) {
		console.error(e);
		return [];
	}
};