import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useEffect } from "react";

interface PayPalButtonProps {
	amount: string
}

const PayPalButton = ({ amount }: PayPalButtonProps) => {

	const [{ isPending }] = usePayPalScriptReducer();

	return (
		<div className="w-96">
			<PayPalButtons
				style={{ layout: "vertical" }}
				createOrder={(data, actions) => {
					return actions.order.create({
						purchase_units: [
							{
								amount: {
									value: amount,
									breakdown: {
										shipping: {
											currency_code: "USD",
											value: "9.99"
										}
									}
								},
							}
						]
					});
				}}
				onApprove={(data, actions) => {
					// Check if 'order' is defined before calling 'capture'
					if (actions.order) {
						return actions.order.capture().then(details => {
							const name = details.payer.name?.given_name;
							alert(`Hello, ${name}`);
							// Perform any additional actions you need after successful payment
						}).catch(error => {
							console.error("Error during payment capture:", error);
							// Handle error case here
						});
					} else {
						console.error("Order is undefined");
						return Promise.reject("Order is undefined");
					}
				}}

			/>
		</div>
	);
};

export default PayPalButton;
