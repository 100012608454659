import { useParams } from "react-router-dom";
import useRequireAuth from "../hooks/useRequireAuth";
import { useEffect, useState } from "react";
import { OrderUpdateType, StatusType, getFormWithID, updateOrderData } from "../server/db";
import { DocumentData } from "firebase/firestore";
import BaseBlock from "../components/BaseBlock";
import EditableText from "../components/EditableText";


const Order = () => {

	const { uid, orderID } = useParams();

	const user = useRequireAuth();

	const [orderData, setOrderData] = useState<DocumentData | undefined>(undefined);

	useEffect(() => {

		if (!(user && orderID)) return;

		const _fetchFormData = async () => {
			const formData = await getFormWithID(uid || "", orderID);
			setOrderData(formData);
		};

		_fetchFormData();

	}, [user, orderID]);

	const handleUpdateData = (data: OrderUpdateType) => {

		if (!(uid && orderID)) return;

		updateOrderData(uid, orderID, data);
	};

	const {
		order,
		option,
		firstName,
		lastName,
		addOne,
		addTwo,
		city,
		country,
		postalCode,
		phone,
		kakao,
		email,
		countryCode,
		rawPrice,
		rawQuantity,
		link,
		serviceFeeStatus,
		shippingFeeStatus,
		trackingCode,
		eventData,
		status
	} = orderData || {};

	const { eFirstName, eLastName, eBirthday, messenger, mid, ePhone, eCountryCode, eEmail, nationality } = eventData || {};

	return (
		<div className="w-[980px] mx-auto mt-20">
			<OrderStatusContainer option={orderData?.option} status={status} handleUpdate={handleUpdateData} />
			<button onClick={() => console.log(orderData)}>print</button>
			{/* {orderID} */}
			<BaseBlock label="Status">
				<OrderStatus status={status} />
			</BaseBlock>
			<BaseBlock label="Order Option">
				<table>
					<tbody>
						<tr>
							<th className="w-32 h-6 text-left">Order Type</th>
							<td>{order}</td>
						</tr>
						<tr>
							<th className="w-32 h-6 text-left">Order Option</th>
							<td>{option}</td>
						</tr>
					</tbody>
				</table>

			</BaseBlock>
			<BaseBlock label="Event Details">
				<table>
					<tbody>
						<tr>
							<th>Name</th>
							<td>{eFirstName} {eLastName}</td>
						</tr>
						<tr>
							<th>Birthday</th>
							<td>{eBirthday}</td>
						</tr>
						<tr>
							<th>Nationality</th>
							<td>{nationality}</td>
						</tr>
						<tr>
							<th>Messenger</th>
							<td>{messenger}</td>
						</tr>
						<tr>
							<th>Messenger ID</th>
							<td>{mid}</td>
						</tr>
						<tr>
							<th>Phone</th>
							<td>({eCountryCode}) {ePhone}</td>
						</tr>
						<tr>
							<th>Email</th>
							<td>{eEmail}</td>
						</tr>

					</tbody>
				</table>
			</BaseBlock>
			<BaseBlock label="Order Details">
				<table>
					<tr>
						<th className="w-32 h-6 text-left">Link</th>
						<td>{link}</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Raw Price</th>
						<td>{rawPrice}</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Quantity</th>
						<td>{rawQuantity}</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Service Fee</th>
						<td>{serviceFeeStatus?.estimatedFee}</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Fulfilled</th>
						<td>{serviceFeeStatus?.isFulfilled ? "true" : "false"}</td>
					</tr>
				</table>
			</BaseBlock>
			<BaseBlock label="Personal Info" >
				<table>
					<tr>
						<th className="w-32 h-6 text-left">Name</th>
						<td>{firstName} {lastName}</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Address</th>
						<td>{addOne} {addTwo} {city} {country} {postalCode}</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Phone</th>
						<td>({countryCode}) {phone}</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Email</th>
						<td>{email}</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Kakao</th>
						<td>{kakao}</td>
					</tr>
				</table>
			</BaseBlock>
			<BaseBlock label="Shipping Status">
				<table>
					<tr>
						<th className="w-32 h-6 text-left">Shipping Fee</th>
						<td>
							<EditableText isEditing={false} value={shippingFeeStatus?.estimatedFee} placeholder="shipping fee" handleChange={(v: string) => { }} />
						</td>
					</tr>
					<tr>
						<th className="w-32 h-6 text-left">Fulfilled</th>
						<td>{shippingFeeStatus?.isFulfilled ? "true" : "false"}</td>
					</tr>
				</table>
			</BaseBlock>

		</div>
	);
};

interface OrderStatusProps {
	status: string
}

const OrderStatus = ({ status }: OrderStatusProps) => {
	if (status === "Submitted") {
		return (
			<div>Your order is submitted and is waiting for checkout.</div>
		);
	} else if (status === "Preparing") {
		return (
			<div>We are preparing your order to be shipped.</div>
		);
	} else if (status === "Ready") {
		return (
			<div>Your product is ready to be shipped.</div>
		);
	} else if (status === "Shipping") {
		return (
			<div>Your order is being shipped.</div>
		);
	} else if (status === "Complete") {
		return (
			<div>Your order is delivered and complete.</div>
		);
	}
	else return (
		<div></div>
	);

};

interface OrderStatusContainerProps {
	option: string
	status: StatusType
	handleUpdate: (data: OrderUpdateType) => void
}

const OrderStatusContainer = ({ option, status, handleUpdate }: OrderStatusContainerProps) => {
	if (option === "E") {
		return (
			<div className="flex flex-row justify-between w-full mb-8" >
				{/* {option} */}
				<OrderStatusEntry
					name="Submitted"
					note="Your order is submitted and is waiting for checkout."
					selected={status}
					handleUpdate={handleUpdate}
				/>
				<OrderStatusEntry
					name="Preparing"
					note="We are preparing your order to be shipped."
					selected={status}
					handleUpdate={handleUpdate}
				/>
				<OrderStatusEntry
					name="Ready"
					note="Your product is ready to be shipped."
					selected={status}
					handleUpdate={handleUpdate}
				/>
				<OrderStatusEntry
					name="Shipping"
					note="Your order is being shipped."
					selected={status}
					handleUpdate={handleUpdate}
				/>
				<OrderStatusEntry
					name="Complete"
					note="Your order is delivered and complete."
					selected={status}
					handleUpdate={handleUpdate}
				/>
			</div>
		);
	} else {
		return (
			<div className="flex flex-row gap-4 justify-between mb-8">
				{/* {option} */}
				<OrderStatusEntry
					name="Submitted"
					note="Your order is submitted and is waiting for checkout."
					selected={status}
					handleUpdate={handleUpdate}
				/>
				<OrderStatusEntry
					name="Preparing"
					note="We are preparing your order to be shipped."
					selected={status}
					handleUpdate={handleUpdate}
				/>
				<OrderStatusEntry
					name="Shipping"
					note="Your order is being shipped."
					selected={status}
					handleUpdate={handleUpdate}
				/>
				<OrderStatusEntry
					name="Complete"
					note="Your order is delivered and complete."
					selected={status}
					handleUpdate={handleUpdate}
				/>
			</div>
		);
	}
};

interface OrderStatusEntryProps {
	name: StatusType
	note: string
	selected: string
	handleUpdate: (data: OrderUpdateType) => void
}

const OrderStatusEntry = ({ name, note, selected, handleUpdate }: OrderStatusEntryProps) => {

	const handleSelect = () => {
		const isConfirmed = window.confirm("Change to " + name + "?");

		if (isConfirmed) {
			handleUpdate({ status: name });
		}
	};

	return (
		// <div className={`flex flex-col gap-4 items-center p-4 border text-center border-solid border-gray-200 w-48 
		// 	${name == selected ? "" : ""}`}
		// >
		// 	<div>{name}</div>
		// 	<div className="text-sm">{note}</div>
		// </div>
		<div className="relative w-[248.5px]" onClick={handleSelect}>
			<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 60">
				<g id="Layer_1-2" data-name="Layer 1">
					<path stroke={name == selected ? "#3D86F6" : "#3a3a3a"} d="m183.93,1l14.94,28.51-14.76,29.49H1.82l14.54-29.05.23-.46-.24-.45L1.65,1h182.28m.6-1H0l15.47,29.5L.2,60h184.53l15.26-30.5L184.74.38l-.2-.38h0Z" />
				</g>
			</svg>
			<div className={"absolute top-[22px] left-20 font-bold" + (name == selected ? " text-blue-500" : "")}>
				{name}
			</div>
		</div>


	);
};

export default Order;