import { useEffect, useState } from "react";
import TextInput from "../components/TextInput";
import { FormDataType, fetchAllForms } from "../server/db";
import { Link } from "react-router-dom";
import BaseBlock from "../components/BaseBlock";
import useRequirePrivilege from "../hooks/useRequirePrivilege";
import { Timestamp } from "firebase/firestore";
import { compareDate, getDateFromKST, setDigits } from "../utils";

const Admin = () => {

	const [formDataList, setFormDataList] = useState<FormDataType[]>([]);

	const admin = useRequirePrivilege();

	useEffect(() => {

		const _fetchAllForms = async () => {
			const forms = await fetchAllForms();
			setFormDataList(forms);
		};

		_fetchAllForms();

	}, []);

	const test = () => {
		const now = new Date();
		const kst = getDateFromKST(2024, 4, 10);
		const kst2 = getDateFromKST(2024, 4, 6);

		console.log(now.toDateString(), kst.toDateString());
		console.log(now < kst);
		console.log(now < kst2);
	};

	const OrderEntryList = formDataList.map(formData => <OrderEntry data={formData} key={formData.id} />);

	return (
		<div className="w-[980px] mx-auto mt-16">
			<BaseBlock label="">
				{compareDate(new Date(), getDateFromKST(24, 4, 10)) ? "yes" : "no"}
				{compareDate(new Date(), getDateFromKST(24, 3, 8)) ? "yes" : "no"}
				<div onClick={test}>test</div>
			</BaseBlock>
			<BaseBlock label="All Orders" >
				{OrderEntryList}
			</BaseBlock>
		</div>
	);
};

interface OrderEntryProps {
	data: FormDataType
}


const OrderEntry = ({ data }: OrderEntryProps) => {

	const { id, uid, order, option, firstName, lastName, link, rawPrice, rawQuantity, addOne, addTwo, city, country, postalCode, note, createdAt } = data;

	const [isOpened, setIsOpened] = useState<boolean>(false);

	const date = createdAt.toDate();

	return (
		<div className={"border border-solid border-gray-200 rounded-md px-4 py-3 transition duration-500 ease-in-out flex flex-col "
		}>
			<div className="flex flex-row gap-4 ">
				<div className="bg-gray-100 border-solid border-gray-200 border rounded w-24 h-10 flex flex-col items-center justify-center">
					Submitted
				</div>
				<div className="bg-gray-100 border-solid border-gray-200 border rounded w-32 h-10 flex flex-col items-center justify-center">
					{date.getFullYear()} - {setDigits(date.getMonth() + 1, 2)} - {setDigits(date.getDate(), 2)}
				</div>
				<div className="w-64 h-10 items-center flex">
					<b>ID: &nbsp;</b>{id}
				</div>
				<Link to={`/admin/orders/${uid}/${id}`} >
					<div className="bg-blue-500 text-white rounded-full px-4 py-2 h-10">Details</div>
				</Link>
			</div>
			{isOpened &&
				<div className="grid grid-cols-2 pt-4 px-1">
					<div>Order Type</div>
					<div>{order}</div>
					<div>Purchase Option</div>
					<div>{option}</div>
					<div>Product Link</div>
					<div className="col-span-1">{link}</div>
					<div>Product Price</div>
					<div className="col-span-1">{rawPrice}</div>
					<div>Product Quantity</div>
					<div className="col-span-1">{rawQuantity}</div>
					<div>Address</div>
					<div className="col-span-1">{addOne} {addTwo},<br /> {city}, {country} ({postalCode})</div>
				</div>
			}
		</div>
	);
};


export default Admin;