import { useContext } from "react";
import { handleSignOut } from "../server/auth";
import { UserContext } from "./UserContext";
import { Link } from "react-router-dom";

const Header = () => {

	const { user } = useContext(UserContext);

	return (
		<div className="z-50 w-full sticky top-0  bg-white">
			<div className=" max-w-[1680px] h-16 mx-auto  flex flex-row justify-between items-center sticky">
				<Link to={"/"}><div>kpopinme proxy</div></Link>
				<div className="flex flex-row gap-8">
					<Link to="/form"><div>Place Order</div></Link>
					<div>|</div>
					<Link to="/trust"><div>Proof of Trust</div></Link>
					<div>|</div>
					<Link to="/contact"><div>Contact Us</div></Link>
					<div>|</div>
					<Link to="event-calendar"><div>Event Calender</div></Link>
					<div>|</div>
					<Link to="my-orders"><div>My Orders</div></Link>


				</div>
				{user ? <div onClick={handleSignOut} className="cursor-pointer">Sign Out</div> : <Link to="/login">Sign In</Link>}
			</div>
		</div>

	);
};

export default Header;