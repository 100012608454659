import { useContext } from "react";
import { UserContext } from "../components/UserContext";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {

	const context = useContext(UserContext);

	return (
		<div className="w-[1680px] ml-[120px] pt-20 pb-64">
			{/* <div className="flex flex-row justify-between items-center">
				<div className="flex flex-col font-bold leading-[80px] text-[80px]">
					Dear<br /> International<br />K-POP<br />Fans,
				</div>
				<div className="flex flex-col font-light text-[40px] leading-[50px]">
					Hard to order from Korea?<br />
					Tired of complex proxy processes?<br />
					Want free international shipping?
				</div>
			</div> */}
			<Helmet>
				<title>KPOPINME Proxy</title>
				<meta name="description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:title" content="KPOPINME Proxy" />
				<meta property="og:description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:image" content="https://proxy.kpopin.me/images/logo.svg" />
			</Helmet>
			<div className="flex flex-col items-center gap-20">
				<div className="font-bold text-[80px] ">Dear International K-POP Fans,</div>
				{/* <div className="font-bold text-[80px] ">Dear international K-POP fans,</div> */}
				<div className="flex flex-col font-light text-[32px] leading-[40px] text-center ">
					Ordering from Korea too hard?<br />
					Tired of complex proxy processes?<br />
					Want free international shipping?
				</div>
			</div>
			<div className="flex flex-col relative items-center mt-32 pt-28">
				<div className="font-bold text-white text-[60px]">
					We can help.
				</div>
				<div className=" flex flex-row gap-4">
					<Link to="/form"><div className="flex flex-col items-center justify-center w-32 h-10 px-4 py-2 rounded-full font-bold bg-blue-500 text-white">Order Now</div></Link>
					<div className="flex flex-col items-center justify-center w-32 h-10 px-4 py-2 rounded-full bg-white ">Read On</div>
				</div>
				<div className="bg-white w-full h-[660px] mt-48 flex flex-row items-center justify-between py-16 gap-x-16">
					<div className="flex flex-col">
						<div className="font-bold text-[64px]">
							kpopinme proxy
						</div>
						<div className="text-[28px]">
							Is a purchase agent service tailored to international K-POP fans.<br />
							We strive to provide the smoothest purchasing experience to<br />
							K-POP fans abroad.<br />
						</div>
					</div>
					<div className="w-[400px] h-[400px">
						<img src="/images/logo.svg" />
					</div>
				</div>
				<img className="absolute top-0 -z-10" src="/images/lp.png" />
			</div>

			<div className="w-full  mt-96 flex flex-col py-16 gap-x-32 items-center">
				<div className="col-span-3 text-6xl mb-16">
					Benefits
				</div>
				<div className="flex flex-row gap-8 items-center justify-between w-full">
					<div className="rounded-2xl flex flex-col py-10 w-96 h-96 items-center text-center">
						<div className="h-28 w-28 mb-12"><img src="/icons/headset.svg" /></div>
						<div className="text-4xl font-medium mb-4">
							We order for you.
						</div>
						<div className="text-2xl">
							Tell us your desired product or event participation, and we will take care of the order process for you.
						</div>
					</div>
					<div className="w-2 h-2 bg-[#3a3a3a] rounded-full" />
					<div className="rounded-2xl flex flex-col py-10 w-96 h-96 items-center text-center">
						<div className="h-32 w-32 mb-8">
							<img src="/icons/money.svg" />
						</div>
						<div className="text-4xl font-medium mb-4">
							One-time payment.
						</div>
						<div className="text-2xl">
							No unexpected extra fees. Pay once, and your purchase is done.
						</div>
					</div>
					<div className="w-2 h-2 bg-[#3a3a3a] rounded-full" />
					<div className="rounded-2xl flex flex-col py-10 w-96 h-96 items-center text-center">
						<div className="h-32 w-32 mb-8">
							<img src="/icons/free-shipping.svg" />
						</div>
						<div className="text-4xl font-medium mb-4">
							Free shipping.
						</div>
						<div className="text-2xl">
							Free international, domestic shipping fee for POB / POB + Inclusion options.
						</div>
					</div>
				</div>

			</div>

			<div className="mt-32 h-[800px] flex flex-row justify-between items-center">
				<div className="flex flex-col gap-8">
					<div className="text-[64px] font-bold leading-[64px]">
						order options<br />
						to optimize<br />
						your purchase
					</div>
					<div className="w-[430px] text-xl">
						We prepared 3 order options so that you can optimize your shipping and service fees. Feel free to contact us if you have trouble choosing.
					</div>
					<div className="flex flex-row gap-4">
						<Link to="/form" >
							<div className="border border-solid border-gray-600 text-lg rounded-full  flex flex-col items-center justify-center px-4 py-2 w-40">
								Estimate Fee
							</div>
						</Link>
						<Link to="/contact" >
							<div className="border border-solid border-gray-600 text-lg rounded-full  flex flex-col items-center justify-center px-4 py-2 w-40">
								Contact Us
							</div>
						</Link>
					</div>

				</div>
				<div className="w-[1000px] h-[540px] grid grid-cols-4 text-2xl text-center">
					<div />
					<div className="font-bold">POB Only</div>
					<div className="font-bold">POB + Inclusions</div>
					<div className="font-bold">Everything</div>
					<div className="col-span-4 bg-black h-1" />
					<div className="text-left">Service Fee</div>
					<div>Free</div>
					<div>Free</div>
					<div>Free</div>
					<div className="col-span-4 bg-black h-[2px]" />
					<div className="text-left">Album Handling Fee</div>
					<div>$0.2 Discount</div>
					<div>$1</div>
					<div>Free</div>
					<div className="col-span-4 bg-black h-[2px]" />
					<div className="text-left">Shipping Fee</div>
					<div>Free</div>
					<div>Free</div>
					<div>Weight-Dependant</div>
					<div className="col-span-4 bg-black h-[2px]" />
					<div className="col-span-4 text-right">*$5 Service Fee for Event Participations</div>
				</div>
			</div>
			<div className="w-full flex items-center flex-row gap-8 justify-center ">
				<Link to="/form" >
					<div className="w-64 h-16 bg-blue-500 text-white text-2xl rounded-full font-bold flex flex-col items-center justify-center">
						Order Now
					</div>
				</Link>
				<Link to="/login" >
					<div className="w-64 h-16 border-2 border-solid border-gray-600 text-2xl rounded-full font-medium flex flex-col items-center justify-center">
						Sign Up
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Home;