import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";

// Custom Hook for checking authentication status
const useRequireAuth = () => {
	const navigate = useNavigate();
	const auth = getAuth();

	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			if (!user) {
				// If the user is not logged in, redirect to /login
				navigate("/login");
			} else {
				setUser(user);
			}
		});
		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, [navigate, auth]);

	// Return the current user (if logged in) or null
	return user;
};

export default useRequireAuth;
