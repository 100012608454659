import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import UserProvider from "../components/UserContext";
import Footer from "../components/Footer";

const Root = () => {
	return (
		<div >
			<UserProvider>
				<Header />
				<div className="mx-auto">
					<Outlet />
				</div>
				<Footer />
			</UserProvider>
		</div>
	);
};

export default Root;