import { ReactElement, ReactNode, cloneElement } from "react";

export interface RadioButtonOption<RestType> {
	label: string
	value: string
	rest?: RestType
}

interface RadioGroupProps<RestType> {
	label: string
	options: RadioButtonOption<RestType>[]
	selected: string
	wrapper: ReactElement
	containerStyle?: string
	handleSelect: (v: string) => void
}

const RadioGroup = <RestType,>({ label, options, selected, containerStyle, handleSelect, wrapper }: RadioGroupProps<RestType>) => {

	const RadioButtonList = options.map((e, i) =>
		<RadioButton option={e} handleSelect={handleSelect} selected={selected} key={i}
			wrapper={wrapper}
		/>
	);

	return (
		<div>
			<div className="mb-4 text-lg font-semibold">
				{label}
			</div>
			<div className={containerStyle}>
				{RadioButtonList}
			</div>
		</div>
	);
};

interface RadioButtonProps<RestType> {
	selected: string
	option: RadioButtonOption<RestType>
	handleSelect: (v: string) => void
	wrapper: ReactElement
	rest?: object
}

const RadioButton = <RestType,>({ selected, option, handleSelect, wrapper, rest }: RadioButtonProps<RestType>) => {

	const { label, value } = option;

	return (
		<div
			className="cursor-pointer"
			onClick={() => handleSelect(value)}
		>
			{cloneElement(wrapper, { ...rest, option, selected, })}
		</div>
	);

};

export default RadioGroup;