import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import PayPalButton from "../components/PayPalButton";
import BaseBlock from "../components/BaseBlock";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFormWithID } from "../server/db";
import useRequireAuth from "../hooks/useRequireAuth";
import { DocumentData } from "firebase/firestore";

const initialOptions = {
	clientId: process.env.REACT_APP_PAYPAL_ID || "",
	currency: "USD",
};

const Checkout = () => {

	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);

	const id = queryParams.get("id");

	const user = useRequireAuth();

	const [orderData, setOrderData] = useState<DocumentData | undefined>(undefined);

	useEffect(() => {
		// Define an async function inside the useEffect
		const fetchData = async () => {
			if (id && user) {
				const formData = await getFormWithID(user.uid, id);
				setOrderData(formData);
			}
		};
		// Call the async function
		fetchData();

		// You can return a cleanup function here if needed
	}, [id, user]); // Dependencies array

	const { firstName, lastName, addOne, addTwo, city, country, link, nationality, option, order, rawPrice, rawQuantity, postalCode } = orderData || {};

	return (
		<PayPalScriptProvider options={initialOptions}>
			<div className="flex flex-col gap-4 pt-8 bg-[#fcfcfc]">
				<div className="w-[1280px] mx-auto grid grid-cols-12 gap-8">
					<div className="col-span-9">
						{orderData &&
							<BaseBlock label="Checkout" >
								<div className="grid grid-cols-2">
									<div className="grid grid-cols-2">
										<div className="mb-4 col-span-2">Order Details</div>
										<b>Order Option </b>
										{option}
										<b>Product Link </b>
										{link}
										<b>Product Price </b>
										{rawPrice}
										<b> Product Quantity </b>
										{rawQuantity}
										<b>Name </b>
										{firstName} {lastName}
										<b>Address </b>
										{addOne} {addTwo}, {city}, {country}
									</div>
								</div>

							</BaseBlock>
						}
					</div>

					<div className="col-span-3">
						<PayPalButton amount="10.00" />

					</div>

				</div>

			</div>

		</PayPalScriptProvider>
	);
};

export default Checkout;