import { useEffect, useState } from "react";
import { EventData, EventType, addNewArtist, addNewCompany, createNewEvent, fetchAllArtists, fetchAllCompanies, fetchAllEvents } from "../server/db";
import useRequirePrivilege from "../hooks/useRequirePrivilege";
import BaseBlock from "../components/BaseBlock";
import TextInput from "../components/TextInput";
import Select from "react-select";
import { compareDate, formatDate, formatDateShort, getDateFromKST, parseRawDate, removeBlank } from "../utils";
import { start } from "repl";

const AdminEvents = () => {

	const [artists, setArtists] = useState<string[]>([]);
	const [companies, setCompanies] = useState<string[]>([]);

	const [newArtist, setNewArtist] = useState<string>("");
	const [newCompany, setNewCompany] = useState<string>("");

	const [artist, setArtist] = useState<number | undefined>(undefined);
	const [company, setCompany] = useState<number | undefined>(undefined);
	const [eventType, setEventType] = useState<EventType | undefined>(undefined);
	const [name, setName] = useState<string>("");
	const [rawStartDate, setRawStartDate] = useState<string>("");
	const [rawEndDate, setRawEndDate] = useState<string>("");
	const [rawEventDate, setRawEventDate] = useState<string>("");
	const [link, setLink] = useState<string>("");

	const [events, setEvents] = useState<EventData[]>([]);
	const [filteredEvents, setFilteredEvents] = useState<EventData[]>([]);


	useRequirePrivilege();

	useEffect(() => {

		const _setEvents = async () => {
			const fetchedEvents = await fetchAllEvents();
			setEvents(fetchedEvents);
			setFilteredEvents(fetchedEvents);
		};


		const _setMetadata = async () => {
			const artists = await fetchAllArtists();
			const companies = await fetchAllCompanies();
			setArtists(artists);
			setCompanies(companies);
		};

		_setEvents();
		_setMetadata();

	}, []);

	const handleAddArtist = async () => {
		await addNewArtist(newArtist);
		setNewArtist("");
	};

	const handleAddCompany = async () => {
		await addNewCompany(newCompany);
		setNewCompany("");
	};

	const artistOptions = artists.map((v, i) => ({ value: i, label: v }));
	const companyOptions = companies.map((v, i) => ({ value: i, label: v }));
	const eventTypeOptions = [
		{ value: 1, label: "Luckydraw" },
		{ value: 2, label: "Fan Signing" },
		{ value: 3, label: "Video Call" },
		{ value: 4, label: "Pre Order" }
	];

	const copyTextToClipboard = async () => {
		const text =
			`${artists[artist || 0]} - [${name}] ${eventType} Event!\n\nApplication Period: ${formatDateShort(parseRawDate(rawStartDate))} ~ ${formatDateShort(parseRawDate(rawEndDate))}\nEvent Date: ${formatDate(parseRawDate(rawEventDate))}\n\nCheck out all ${artists[artist || 0]} events at our K-POP Event Calendar(proxy.kpopin.me/event-calendar)!\n\n#${removeBlank(artists[artist || 0])} #${removeBlank(companies[company || 0])}`
			;

		await navigator.clipboard.writeText(text);
	};

	const handleSubmit = async () => {

		if (artist === undefined || company === undefined || eventType === undefined) return;

		const startDate = parseRawDate(rawStartDate);
		const endDate = parseRawDate(rawEndDate);
		const eventDate = parseRawDate(rawEventDate);

		await createNewEvent({ name, eventType, artist: artists[artist], company: companies[company], startDate, endDate, eventDate, link });
		await copyTextToClipboard();
		window.location.reload();
	};

	const eventEntryList = filteredEvents.map((v, i) => <EventEntry eventData={v} key={i} />);


	return (
		<div className="w-[1280px] mx-auto">
			<div className="flex flex-row gap-4">{artists.map((v, i) => <div key={i}>{v}</div>)}</div>
			<div className="flex flex-row gap-4">{companies.map((v, i) => <div key={i}>{v}</div>)}</div>
			<BaseBlock label="" >
				<div className="flex flex-row gap-4">
					<TextInput placeholder="Artist name" value={newArtist} handleChange={setNewArtist} />
					<button onClick={handleAddArtist}>Add</button>
				</div>
				<div className="flex flex-row gap-4">
					<TextInput placeholder="Company name" value={newCompany} handleChange={setNewCompany} />
					<button onClick={handleAddCompany}>Add</button>
				</div>

			</BaseBlock>
			<BaseBlock label="">
				<Select options={artistOptions} onChange={option => setArtist(option?.value)} placeholder="Select Artist" />
				<Select options={companyOptions} onChange={option => setCompany(option?.value)} placeholder="Select Company" />
				<Select options={eventTypeOptions} onChange={option => setEventType(option?.label as EventType)} placeholder="Select Type" />
				<TextInput placeholder="Album Name" value={name} handleChange={setName} />
				<TextInput placeholder="Start Date" value={rawStartDate} handleChange={setRawStartDate} />
				<TextInput placeholder="End Date" value={rawEndDate} handleChange={setRawEndDate} />
				<TextInput placeholder="Event Date" value={rawEventDate} handleChange={setRawEventDate} />
				<TextInput placeholder="Link" value={link} handleChange={setLink} />
				<button onClick={handleSubmit}>submit</button>
			</BaseBlock>
			<BaseBlock label="">
				<div className="flex flex-col">

					<div className="grid grid-cols-12 font-bold bg-gray-100 px-4 py-3 gap-4">
						<div className="col-span-2">Artist</div>
						<div className="col-span-2">Event Name</div>
						<div className="col-span-2">Company</div>
						<div className="col-span-2">Apply Date</div>
						<div className="col-span-2">Event Date</div>
						<div className="col-span-1">Link</div>
					</div>
					{eventEntryList}

				</div>
			</BaseBlock>
		</div>
	);
};

interface EventEntryProps {
	eventData: EventData
}

const EventEntry = ({ eventData }: EventEntryProps) => {

	const { name, eventType, artist, company, startDate, endDate, eventDate, link } = eventData;

	const generateTweetLink = () => {
		const baseURL = "https://twitter.com/intent/tweet?text=";

		const text =
			`${artist} - [${name}] ${eventType} Event!\n\nApplication Period: ${formatDateShort(startDate)} ~ ${formatDateShort(endDate)}\nEvent Date: ${formatDate(eventDate)}\n\nCheck out all ${artist} events at our K-POP Event Calendar(proxy.kpopin.me/event-calendar)!\n\n#${removeBlank(artist)} #${removeBlank(company)}`
			;

		return baseURL + encodeURIComponent(text);
	};

	const copyTextToClipboard = async () => {
		const text =
			`${artist} - [${name}] ${eventType} Event!\n\nApplication Period: ${formatDateShort(startDate)} ~ ${formatDateShort(endDate)}\nEvent Date: ${formatDate(eventDate)}\n\nCheck out all ${artist} events at our K-POP Event Calendar(proxy.kpopin.me/event-calendar)!\n\n#${removeBlank(artist)} #${removeBlank(company)}`
			;

		await navigator.clipboard.writeText(text);
	};

	return (
		<div>
			<div className="grid grid-cols-12 col-span-1 px-4 py-2 gap-4 ">
				<div className="col-span-1">
					{artist}
				</div>
				<div className="col-span-2">
					[{name}] {eventType}
				</div>
				<div className="col-span-2">
					{company}
				</div>
				<div className="col-span-2">
					{formatDateShort(startDate)} ~ {formatDateShort(endDate)}
				</div>
				<div className="col-span-2">
					{formatDate(eventDate)}
				</div>
				<div>
					<a target="_blank" href={`${link}`} rel="noreferrer">
						<div>Click</div>
					</a>
				</div>
				{/* <div>
					<a target="_blank" href={`${generateTweetLink()}`} rel="noreferrer">
						<div>Click</div>
					</a>
				</div> */}
				<div onClick={copyTextToClipboard}>
					copy
				</div>
				<div>
					{compareDate(new Date(), getDateFromKST(endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate())) ? "yes" : "no"}
					{/* {new Date().toDateString()}
					{getDateFromKST(endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()).toDateString()} */}
				</div>
			</div>
			<div className="col-span-12 bg-gray-200 h-[1px]" />

		</div>

	);
};

export default AdminEvents; 