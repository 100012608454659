import { DocumentReference, Timestamp, addDoc, arrayUnion, collection, collectionGroup, doc, getDoc, getDocs, orderBy, query, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import { User } from "firebase/auth";

export const getUserWithUID = async (uid: string) => {

	const userRef = doc(db, "users", uid);

	try {
		const docSnap = await getDoc(userRef);

		if (docSnap.exists()) {
			return docSnap.data();
		} else {
			return null;
		}
	} catch (e) {
		console.error(e);
		return e;
	}
};

export const createNewUser = async (uid: string, displayName: string | null, email: string | null) => {

	const newUserRef = doc(db, "users", uid);

	const [firstName, lastName] = (displayName || "").split(" ", 2);

	try {
		await setDoc(newUserRef, {
			firstName,
			lastName,
			email,
			addOne: "",
			addTwo: "",

		});
	} catch (e) {
		console.error(e);
	}
};

export interface UserInfo {
	firstName?: string
	lastName?: string
	addOne?: string
	addTwo?: string
	city?: string
	country?: string
	postalCode?: string
	phone?: string
	countryCode?: string
	email?: string
	kakao?: string
}

export const updateUserInfo = async (uid: string, updateInfo: UserInfo) => {
	const userRef = doc(db, "users", uid);

	try {
		await updateDoc(userRef, { ...updateInfo });
	} catch (e) {
		console.error(e);
	}
};

export type StatusType = "Submitted" | "Preparing" | "Ready" | "Shipping" | "Complete"

export interface OrderDataType {
	firstName: string
	lastName: string
	link: string
	rawPrice: string
	rawQuantity: string
	addOne: string
	addTwo?: string
	city: string
	country: string
	postalCode: string
	note: string
	phone: string
	email: string
	kakao: string
	countryCode: string
	status: StatusType
}

export interface EventDataType {
	eFirstName: string
	eLastName: string
	eBirthday: string
	messenger: string | undefined
	mid: string
	ePhone: string
	eEmail: string
	eCountryCode: string
	nationality: string
}

export interface FeeStatusType {
	estimatedFee: number
	realFee?: number
	fulfilled: boolean
}

export interface OrderUpdateType {
	firstName?: string
	lastName?: string
	link?: string
	rawPrice?: string
	rawQuantity?: string
	addOne?: string
	addTwo?: string
	city?: string
	country?: string
	postalCode?: string
	note?: string
	phone?: string
	email?: string
	kakao?: string
	countryCode?: string
	status?: StatusType
}

export interface EventUpdateType {
	eFirstName?: string
	eLastName?: string
	eBirthday?: string
	messenger?: string | undefined
	mid?: string
	ePhone?: string
	eEmail?: string
	eCountryCode?: string
	nationality?: string
}

export interface FeeStatusUpdateType {
	estimatedFee?: number
	realFee?: number
	fulfilled?: boolean
}

export const updateOrderData = async (uid: string, id: string, updateData: OrderUpdateType) => {
	const userRef = doc(db, "users", uid);
	const formRef = doc(userRef, "forms", id);

	// try {
	// 	await updateDoc(userRef, { ...updateInfo });
	// } catch (e) {
	// 	console.error(e);
	// }
	try {
		await updateDoc(formRef, { ...updateData });
	} catch (e) {
		console.error(e);
	}
};


export const uploadNewForm = async (
	uid: string,
	option: string,
	serviceFeeStatus: FeeStatusType,
	shippingFeeStatus: FeeStatusType = { estimatedFee: 0, fulfilled: false },
	orderData: OrderDataType,
	eventData: EventDataType | undefined = undefined) => {

	const userRef = doc(db, "users", uid);
	const formRef = collection(userRef, "forms");

	if (!eventData) {
		const newFormDoc = await addDoc(formRef, { order: "L", option, serviceFeeStatus, shippingFeeStatus, ...orderData, createdAt: serverTimestamp() });
		return newFormDoc.id;
	} else {
		const newFormDoc = await addDoc(formRef, { order: "E", option, serviceFeeStatus, shippingFeeStatus, ...orderData, eventData, createdAt: serverTimestamp() });
		return newFormDoc.id;
	}
};

export const getFormWithID = async (uid: string, id: string) => {
	const docRef = doc(db, "users", uid, "forms", id);

	try {
		const docSnap = await getDoc(docRef);
		return docSnap.data();
	} catch (e) {
		console.error(e);
	}
};

type BaseFormDataType = {
	id: string
	createdAt: Timestamp;
}

type OtherFormDataType = {
	[key: string]: string;

}

export type FormDataType = BaseFormDataType & OtherFormDataType


export const fetchUserForms = async (uid: string) => {
	const userRef = doc(db, "users", uid);
	const formRef = collection(userRef, "forms");

	const querySnap = await getDocs(formRef);
	return querySnap.docs.map(doc => ({
		id: doc.id,
		createdAt: doc.data().createdAt,
		...doc.data()
	}));
};

export const fetchAllForms = async () => {
	const formsCollectionGroup = collectionGroup(db, "forms");
	const querySnapshot = await getDocs(formsCollectionGroup);
	const forms = querySnapshot.docs.map(doc => ({
		id: doc.id,
		uid: doc.ref.parent.parent?.id || "",
		createdAt: doc.data().createdAt,
		...doc.data()
	}));

	return forms;
};

export type EventType = "Luckydraw" | "Fan Signing" | "Video Call" | "Pre Order"

export interface EventData {
	eventType: EventType
	name: string
	artist: string
	company: string
	startDate: Date
	endDate: Date
	eventDate: Date
	link: string
}


export const createNewEvent = async (eventData: EventData) => {

	const eventCollectonRef = collection(db, "event-calender", "data", "events");

	try {
		const docRef = await addDoc(eventCollectonRef, eventData);
	} catch (e) {
		console.error(e);
	}

};

export const fetchAllEvents: () => Promise<EventData[]> = async () => {
	const eventCollectonRef = collection(db, "event-calender", "data", "events");
	const q = query(eventCollectonRef, orderBy("endDate", "asc"));

	try {
		const querySnapshot = await getDocs(q);
		const events = querySnapshot.docs.map(doc => {

			const { eventType, name, artist, company, startDate, endDate, eventDate, link } = doc.data();

			return {
				id: doc.id,
				eventType, name, artist, company,
				startDate: (startDate as Timestamp).toDate(),
				endDate: (endDate as Timestamp).toDate(),
				eventDate: (eventDate as Timestamp).toDate(),
				link
			};
		});

		return events;
	} catch (e) {
		console.error(e);
		return [];
	}
};

export const addNewArtist = async (name: string) => {
	const eventMetadataRef = doc(db, "event-calender", "metadata");

	try {
		await updateDoc(eventMetadataRef, {
			artists: arrayUnion(name)
		});
	} catch (e) {
		console.error(e);
	}
};

export const fetchAllArtists = async () => {
	const eventMetadataRef = doc(db, "event-calender", "metadata");

	try {
		const docSnap = await getDoc(eventMetadataRef);
		const { artists } = docSnap.data() || {};

		return artists as string[];

	} catch (e) {
		console.error(e);
		return [];
	}
};

export const addNewCompany = async (name: string) => {
	const eventMetadataRef = doc(db, "event-calender", "metadata");

	try {
		await updateDoc(eventMetadataRef, {
			companies: arrayUnion(name)
		});
	} catch (e) {
		console.error(e);
	}
};

export const fetchAllCompanies = async () => {
	const eventMetadataRef = doc(db, "event-calender", "metadata");

	try {
		const docSnap = await getDoc(eventMetadataRef);
		const { companies } = docSnap.data() || {};

		return companies;

	} catch (e) {
		console.error(e);
		return [];
	}
};