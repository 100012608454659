import { GoogleAuthProvider, getRedirectResult, signInWithPopup, signInWithRedirect, signOut } from "firebase/auth";
import { auth } from "./firebase";
import { resourceLimits } from "worker_threads";
import { createNewUser, getUserWithUID } from "./db";
import { NavigateFunction } from "react-router-dom";

const googleProvider = new GoogleAuthProvider();

const _signInWithGoogle = () => {
	signInWithRedirect(auth, googleProvider);
};

export const handleSignOut = () => {
	signOut(auth).then(() => {
		console.log("sign out success");
	}).catch(e => {
		console.error(e);
	});
};

export const handleSignInWithProvider = (provider: string) => {
	if (provider == "google") {
		_signInWithGoogle();
	}
};

export const handleSignInRedirect = async (): Promise<boolean> => {
	try {
		const res = await getRedirectResult(auth);
		if (res?.user) {
			const user = res.user;
			const userData = await getUserWithUID(user.uid);

			if (!userData) {
				createNewUser(user.uid, user.displayName, user.email);
			}
			return true;
		}
		return false;
	} catch (err) {
		console.error(err);
		return false; // Return false in case of error
	}
};
