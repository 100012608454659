import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";

// Custom Hook for checking authentication status
const useRequirePrivilege = () => {
	const navigate = useNavigate();
	const auth = getAuth();

	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			console.log(user?.uid);
			if (!user) {
				// If the user is not logged in, redirect to /login
				navigate("/login");
			} else {
				if (user.uid === "1dgbG6BEsuelAWaNM2yAjFwwpdk1" || user.uid === "32D22s7M21aUiUdywnuAlY5ojNl2") {
					setUser(user);
				} else {
					navigate("/login");
				}
			}
		});
		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, [navigate, auth]);

	// Return the current user (if logged in) or null
	return user;
};

export default useRequirePrivilege;
