import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./routes/root";
import Form from "./routes/form";
import Login from "./routes/login";
import Home from "./routes/home";
import Checkout from "./routes/checkout";
import Trust from "./routes/trust";
import Contact from "./routes/contact";
import MyOrders from "./routes/myOrders";
import Admin from "./routes/admin";
import Order from "./routes/order";
import AdminOrder from "./routes/adminOrder";
import EventCalendar from "./routes/eventCalendar";
import AdminEvents from "./routes/adminEvents";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		children: [
			{
				path: "",
				element: <Home />
			},
			{
				path: "form",
				element: <Form />
			},
			{
				path: "login",
				element: <Login />
			},
			{
				path: "checkout",
				element: <Checkout />
			},
			{
				path: "trust",
				element: <Trust />
			},
			{
				path: "contact",
				element: <Contact />
			},
			{
				path: "my-orders",
				element: <MyOrders />
			},
			{
				path: "admin",
				element: <Admin />
			},
			{
				path: "orders/:orderID",
				element: <Order />
			},
			{
				path: "admin/orders/:uid/:orderID",
				element: <AdminOrder />
			},
			{
				path: "event-calendar",
				element: <EventCalendar />
			},
			{
				path: "admin/events",
				element: <AdminEvents />
			}
		]
	}
]);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
