import { useEffect, useState } from "react";
import BaseBlock from "../components/BaseBlock";
import { fetchImages } from "../server/storage";
import { Helmet } from "react-helmet";

const Trust = () => {

	const [packageUrls, setPackageUrls] = useState<string[]>([]);

	useEffect(() => {
		const _setImages = async () => {
			const urls = await fetchImages("packages");
			setPackageUrls(urls);
		};

		_setImages();
	}, []);

	const packageImageList = packageUrls.map((url, i) => <img src={url} key={i} />);

	return (
		<div className="bg-[#fcfcfc]">
			<Helmet>
				<title>KPOPINME Proxy | Proof of Trust</title>
				<meta name="description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:title" content="KPOPINME Proxy" />
				<meta property="og:description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:image" content="https://proxy.kpopin.me/images/logo.svg" />
			</Helmet>
			<div className="w-[980px] mx-auto flex flex-col items-center mt-12">
				<div className="text-3xl">Proof of Trust</div>
				<div className="text-xl">Storage & Shipping proof</div>
				<div className="mb-8">We always strive to provide the fastest and cheapest shipping for our customers.</div>
				<BaseBlock label="Shipping History">
					<div className="grid grid-cols-6 gap-4">
						{packageImageList}
					</div>
				</BaseBlock>
			</div>
		</div>

	);
};

export default Trust;