import { useEffect, useState } from "react";
import useRequireAuth from "../hooks/useRequireAuth";
import { FormDataType, fetchUserForms, getUserWithUID, updateUserInfo } from "../server/db";
import BaseBlock from "../components/BaseBlock";
import TextInput from "../components/TextInput";
import useObjectState, { ObjectData } from "../hooks/useObjectState";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import EditableText from "../components/EditableText";
import { setDigits } from "../utils";
import { Timestamp } from "firebase/firestore";

const MyOrders = () => {

	const user = useRequireAuth();

	const [formDataList, setFormDataList] = useState<FormDataType[]>([]);

	const [userData, setUserData] = useObjectState({});

	useEffect(() => {

		if (!user) return;

		const _fetchUserForms = async () => {
			const formDataList = await fetchUserForms(user.uid);
			setFormDataList(formDataList);
		};

		const _fetchUserData = async () => {
			const _userData = await getUserWithUID(user.uid);
			console.log(_userData);
			setUserData(_userData as ObjectData);
		};

		_fetchUserForms();
		_fetchUserData();
	}, [user]);

	const OrderEntryList = formDataList.map(formData => <OrderEntry data={formData} key={formData.id} />);

	const [isEditing, setIsEditing] = useState<boolean>(false);

	const handleSave = async () => {

		if (!user) return;

		setIsEditing(false);
		await updateUserInfo(user.uid, userData);
	};

	const { firstName, lastName, addOne, addTwo, city, country, postalCode, countryCode, phone } = userData;

	return (
		<div className="bg-[#fcfcfc] pt-16">
			<Helmet>
				<title>KPOPINME Proxy | My Orders</title>
				<meta name="description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:title" content="KPOPINME Proxy" />
				<meta property="og:description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:image" content="https://proxy.kpopin.me/images/logo.svg" />
			</Helmet>
			<div className="w-[1280px] flex flex-row gap-8 mx-auto pb-64">
				<BaseBlock label="My Orders" >
					{OrderEntryList}
				</BaseBlock>
				<BaseBlock label="Personal Information" >
					<div className="grid grid-cols-4 gap-2 justify-center items-center">

						<b className="h-12 flex flex-row items-center">Name</b>
						<div className="col-span-1 ">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={firstName}
								handleChange={firstName => setUserData({ firstName })}
							/>

						</div>
						<div className="col-span-1">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={lastName}
								handleChange={lastName => setUserData({ lastName })}
							/>
						</div>

						<div className="col-span-1 justify-end flex flex-row">
							{isEditing ?
								<button
									onClick={handleSave}
									className="bg-gray-100 border-solid border-gray-200 border rounded w-16 h-10 flex flex-col items-center justify-center"
								>
									Save
								</button> :
								<button
									onClick={() => setIsEditing(true)}
									className="bg-gray-100 border-solid border-gray-200 border rounded w-16 h-10 flex flex-col items-center justify-center"
								>
									Edit
								</button>
							}

						</div>
						<b className="h-12 flex flex-row items-center">Address</b>
						<div className="col-span-3">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={addOne}
								handleChange={addOne => setUserData({ addOne })}
							/>
						</div>
						<div />
						<div className="col-span-3">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={addTwo}
								handleChange={addTwo => setUserData({ addTwo })}
							/>
						</div>
						<div />
						<div className="col-span-1">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={city}
								handleChange={city => setUserData({ city })}
							/>
						</div><div className="col-span-1">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={country}
								handleChange={country => setUserData({ country })}
							/>
						</div><div className="col-span-1">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={postalCode}
								handleChange={postalCode => setUserData({ postalCode })}
							/>
						</div>
						<b className="h-12 flex flex-row items-center">Phone Number</b>
						<div className="col-span-1">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={countryCode}
								handleChange={countryCode => setUserData({ countryCode })}
							/>
						</div>
						<div className="col-span-2">
							<EditableText
								placeholder=""
								isEditing={isEditing}
								value={phone}
								handleChange={phone => setUserData({ phone })}
							/>
						</div>
					</div>
				</BaseBlock>
			</div>
		</div>

	);
};



interface OrderEntryProps {
	data: FormDataType
}

const OrderEntry = ({ data }: OrderEntryProps) => {

	const { id, order, status, option, firstName, lastName, link, rawPrice, rawQuantity, addOne, addTwo, city, country, postalCode, note, createdAt } = data;

	const [isOpened, setIsOpened] = useState<boolean>(false);

	const date = createdAt.toDate();

	return (
		<div className={"border border-solid border-gray-200 rounded-md px-4 py-3 transition duration-500 ease-in-out flex flex-col "
		}>
			<div className="flex flex-row gap-4 ">
				<div className="bg-gray-100 border-solid border-gray-200 border rounded w-24 h-10 flex flex-col items-center justify-center">
					{status}
				</div>
				<div className="bg-gray-100 border-solid border-gray-200 border rounded w-32 h-10 flex flex-col items-center justify-center">
					{date.getFullYear()} - {setDigits(date.getMonth() + 1, 2)} - {setDigits(date.getDate(), 2)}
				</div>
				<div className="w-64 h-10 items-center flex">
					<b>ID: &nbsp;</b>{id}
				</div>
				<Link to={`/orders/${id}`} >
					<div className="bg-blue-500 text-white rounded-full px-4 py-2 h-10">Details</div>
				</Link>
			</div>
			{isOpened &&
				<div className="grid grid-cols-2 pt-4 px-1">
					<div>Order Type</div>
					<div>{order}</div>
					<div>Purchase Option</div>
					<div>{option}</div>
					<div>Product Link</div>
					<div className="col-span-1">{link}</div>
					<div>Product Price</div>
					<div className="col-span-1">{rawPrice}</div>
					<div>Product Quantity</div>
					<div className="col-span-1">{rawQuantity}</div>
					<div>Address</div>
					<div className="col-span-1">{addOne} {addTwo},<br /> {city}, {country} ({postalCode})</div>
				</div>
			}
		</div>
	);
};

export default MyOrders;