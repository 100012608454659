import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<div className="bg-[#101820] h-80 w-full flex flex-col items-center justify-center text-gray-400">
			<div className="w-16 h-16">
				<img src="images/logo.svg" />
			</div>
			<div className="mb-12">kpopinme proxy</div>
			<div className="flex flex-row justify-center gap-16 text-sm text-center">
				<Link to="/" className="w-24">Terms of Use</Link>
				<Link to="/" className="w-24">Privacy Policy</Link>
				<Link to="/" className="w-24">About Us</Link>
			</div>
			<div className="flex flex-col text-xs mt-8 text-center">
				<div>www.kpopin.me</div>
				<div>contact@kpopin.me</div>
				<div>(+82)10-2711-1913</div>
			</div>
		</div>
	);
};

export default Footer;