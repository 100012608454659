import BaseBlock from "../components/BaseBlock";
import { Helmet } from "react-helmet";

const Contact = () => {

	return (
		<div className="w-[980px] mx-auto mt-16 mb-64">
			<Helmet>
				<title>KPOPINME Proxy | Contact Us</title>
				<meta name="description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:title" content="KPOPINME Proxy" />
				<meta property="og:description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:image" content="https://proxy.kpopin.me/images/logo.svg" />
			</Helmet>
			<BaseBlock label="Contact Us">
				{/* <button onClick={functionTest}>press</button> */}
				<div>
					We are eager to answer any questions, or to help if you have any problems.<br />
					If you need to reach us, please contact us using any of the information below.<br />
					If you are inquiring about an order, make sure to include your <b>Order ID</b> when you contact us.
				</div>

			</BaseBlock>
			<BaseBlock label="Contact Info">
				<div>
					<div className="text-lg mb-4">Click to open link.</div>
					<b>E-mail</b> contact@kpopin.me<br />
					<a href="https://www.instagram.com/kpop.in.me/" target="_blank" rel="noreferrer"><b>Instagram</b> @kpop.in.me<br /></a>
					<a href="https://www.facebook.com/profile.php?id=61551096015164" target="_blank" rel="noreferrer"><b>Facebook</b> KPOP IN ME</a>
				</div>
			</BaseBlock>
			{/* <PayPalButton2 amount="1.00" /> */}
			{/* <PayPalButton amount="1.00" /> */}
		</div>
	);
};

export default Contact;