import { useContext, useEffect, useState } from "react";
import TextInput from "../components/TextInput";
import BaseBlock from "../components/BaseBlock";
import RadioGroup, { RadioButtonOption } from "../components/RadioGroup";
import { OptionRestType, OptionWrapper, OrderRestType, OrderWrapper } from "../components/wrappers";
import { calculateServiceFee, round } from "../utils";
import Select from "react-select";
import { getUserWithUID, updateUserInfo, uploadNewForm } from "../server/db";
import { UserContext } from "../components/UserContext";
import useRequireAuth from "../hooks/useRequireAuth";
import { Link, useNavigate } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalButton from "../components/PayPalButton";
import PayPalButton2 from "../components/PayPalButton2";
import { Helmet } from "react-helmet";
import useObjectState, { ObjectData } from "../hooks/useObjectState";

const Form = () => {

	const [order, setOrder] = useState<string>("L");

	const [link, setLink] = useState<string>("");
	const [rawPrice, setRawPrice] = useState<string>("");
	const [rawQuantity, setRawQuantity] = useState<string>("");

	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [birthday, setBirthday] = useState<string>("");
	const [nationality, setNationality] = useState<string>("");

	const [addOne, setAddOne] = useState<string>("");
	const [addTwo, setAddTwo] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [country, setCountry] = useState<string>("");
	const [postalCode, setPostalCode] = useState<string>("");

	const [email, setEmail] = useState<string>("");
	const [kakao, setKakao] = useState<string>("");
	const [countryCode, setCountryCode] = useState<string>("");
	const [phone, setPhone] = useState<string>("");

	const [note, setNote] = useState<string>("");
	const [option, setOption] = useState<string>("POB");

	const [eFirstName, setEFirstName] = useState<string>("");
	const [eLastName, setELastName] = useState<string>("");
	const [eBirthday, setEBirthday] = useState<string>("");

	const [messenger, setMessenger] = useState<string | undefined>(undefined);
	const [mid, setMid] = useState<string>("");
	const [eCountryCode, setECountryCode] = useState<string>("");

	const [ePhone, setEPhone] = useState<string>("");
	const [eEmail, setEEmail] = useState<string>("");

	const [isSave, setIsSave] = useState<boolean>(false);

	const serviceFees = calculateServiceFee(parseInt(rawPrice), parseInt(rawQuantity), order);

	const messengerOptions = [
		{ value: "kakao", label: "KakaoTalk" },
		{ value: ",line", label: "Line" },
		{ value: "wechat", label: "WeChat" }
	];

	const user = useRequireAuth();

	const [userData, setUserData] = useObjectState({});

	useEffect(() => {

		if (!user) return;

		const _fetchUserData = async () => {
			const _userData = await getUserWithUID(user.uid);
			setUserData(_userData as ObjectData);
		};

		_fetchUserData();
	}, [user]);

	useEffect(() => {
		const { firstName, lastName, addOne, addTwo, city, country, postalCode, phone, countryCode, email, kakao } = userData;

		setFirstName(firstName);
		setLastName(lastName);
		setAddOne(addOne);
		setAddTwo(addTwo);
		setCity(city);
		setCountry(country);
		setPostalCode(postalCode);
		setPhone(phone);
		setCountryCode(countryCode);
		setEmail(email);
		setKakao(kakao);
	}, [userData]);

	const navigate = useNavigate();

	const handleSubmit = async (isPayed: boolean) => {

		if (!(firstName && lastName && addOne && city && country && email && postalCode)) {
			alert("Enter the required information.");
			return;
		}

		if (getAmount(option) < 150) {
			alert("Total product price must be over $150.");
			return;
		}

		try {
			const id = await uploadNewForm(
				user?.uid || "",
				option,
				{ estimatedFee: getAmount(option), fulfilled: isPayed },
				{ estimatedFee: 100, fulfilled: false },
				{ firstName, lastName, link, addOne, addTwo, city, country, postalCode, note, rawPrice, rawQuantity, phone, kakao, email, countryCode, status: isPayed ? "Preparing" : "Submitted" },
				order == "E" ? { eFirstName, eLastName, eBirthday, nationality, messenger, mid, ePhone, eCountryCode, eEmail } : undefined
			);
			if (!isPayed) {
				navigate("/my-orders");
			}
		} catch (e) {
			console.error(e);
		}

		if (isSave && user) {
			try {
				await updateUserInfo(user.uid, {
					firstName, lastName, addOne, addTwo, city, country, postalCode, phone, countryCode, email, kakao
				});
			} catch (e) {
				console.error(e);
			}
		}
	};

	const initialOptions = {
		clientId: process.env.REACT_APP_PAYPAL_ID || "",
		currency: "USD",
	};

	const getAmount = (option: string) => {
		if (option == "POB") {
			return serviceFees.POB.tot;
		} else if (option == "POB_I") {
			return serviceFees.POB_I.tot;
		} else if (option == "E") {
			return serviceFees.E.tot;
		} else return 0;
	};

	return (
		<PayPalScriptProvider options={initialOptions}>
			<Helmet>
				<title>KPOPINME Proxy | Order</title>
				<meta name="description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:title" content="KPOPINME Proxy" />
				<meta property="og:description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:image" content="https://proxy.kpopin.me/images/logo.svg" />
			</Helmet>
			<div className="flex flex-col gap-4 pt-8 pb-16 bg-[#fcfcfc]">
				<div className="w-[980px] mx-auto">
					<BaseBlock label="">
						<div className="text-lg">
							Questioning our credibility? Check out our <Link to="/trust" className="text-blue-500">Proof of Trust</Link> page!
							We have already sent out hundreds of parcels to cutomers all over the world. You can be our next.
						</div>
					</BaseBlock>
					<BaseBlock label="">
						<div className="text-lg">
							The total product price of your order must be greater than $150. For orders less than $150, please DM us at instagram <a className="text-blue-500" href="https://www.instagram.com/kpop.in.me/" target="_blank" rel="noreferrer">@kpop.in.me</a>.
						</div>
					</BaseBlock>
					<BaseBlock label="Order Type">
						<RadioGroup<OrderRestType>
							label="Order Type"
							options={[
								{
									label: "General Order",
									value: "L",
									rest: {
										note: "Orders that do not require personal information, such as Luckydraws."
									}
								},
								{
									label: "Event Participation",
									value: "E",
									rest: {
										note: "Events that require personal information, such as Fan Signing Events."
									}
								},
							]}
							containerStyle="flex flex-row gap-4"
							handleSelect={(v: string) => setOrder(v)}
							selected={order}
							wrapper={<OrderWrapper />}
						/>
					</BaseBlock>
					{order == "E" &&
						<BaseBlock label="Event Application Info">
							<div>*You <b>MUST</b> enter the name of the person who is attending the event.</div>
							<div className="grid grid-cols-12 gap-4">
								<TextInput placeholder="First Name" value={eFirstName} handleChange={setEFirstName} style="col-span-3" />
								<TextInput placeholder="Last Name" value={eLastName} handleChange={setELastName} style="col-span-3" />
								<TextInput placeholder="Date of Birth(MM/DD/YYYY)" value={eBirthday} handleChange={setEBirthday} style="col-span-3" />
								<TextInput placeholder="Nationality" value={nationality} handleChange={setNationality} style="col-span-3" />
								<div className="col-span-12">*You <b>MUST</b> enter the messenger that the event host requires, and the phone number <b>Associated With</b> your messenger ID.</div>
								<div className="col-span-3 h-12">
									<Select options={messengerOptions} placeholder="Messenger Type"
										styles={{
											control: (provided) => ({
												...provided,
												height: "48px",
												minHeight: "48px",
												borderRadius: "4px",
												borderColor: "rgb(229, 231, 235)",
												paddingLeft: 4
											}),
											valueContainer: (provided) => ({
												...provided,
												height: "48px",
												display: "flex",
												alignItems: "center",
											}),
										}}
										value={messengerOptions.find(option => option.value === messenger)}
										onChange={option => setMessenger(option?.value)}
									/>
								</div>
								<TextInput placeholder={(messengerOptions.find(option => option.value === messenger)?.label || "Messenger") + " ID"} value={mid} handleChange={setMid} style="col-span-3" />
								<TextInput placeholder="Country Code" value={eCountryCode} handleChange={setECountryCode} style="col-span-2" />
								<TextInput placeholder="Phone Number" value={ePhone} handleChange={setEPhone} style="col-span-4" />
								<TextInput placeholder="Email Address(To receive your event notifications)" value={eEmail} handleChange={setEEmail} style="col-span-12" />
							</div>
						</BaseBlock>
					}
					<BaseBlock label="Order Form">

						<div className="grid grid-cols-12 gap-4">
							<label className="col-span-10">Personal Information</label>
							<div className="col-span-2">
								<label>
									<input type="checkbox" onClick={() => setIsSave(!isSave)}></input>
									&nbsp;Save Personal Info
								</label>
							</div>
							<div className="grid grid-cols-12 gap-4 col-span-12">
								<TextInput placeholder="First Name" value={firstName} handleChange={setFirstName} style="col-span-4" />
								<TextInput placeholder="Last Name" value={lastName} handleChange={setLastName} style="col-span-4" />
							</div>
							<label className="col-span-12">Product Info</label>
							<TextInput placeholder="Link to the product" value={link} handleChange={setLink} style="col-span-12" />
							<TextInput placeholder="Product Price(KRW)" value={rawPrice} handleChange={setRawPrice} style="col-span-6" />
							<TextInput placeholder="Quantity" value={rawQuantity} handleChange={setRawQuantity} style="col-span-6" />
							<label className="col-span-12">Address</label>
							<TextInput placeholder="Address line 1" value={addOne} handleChange={setAddOne} style="col-span-6" />
							<TextInput placeholder="Address line 2(optional)" value={addTwo} handleChange={setAddTwo} style="col-span-6" />
							<TextInput placeholder="City" value={city} handleChange={setCity} style="col-span-4" />
							<TextInput placeholder="Country" value={country} handleChange={setCountry} style="col-span-4" />
							<TextInput placeholder="Postal Code" value={postalCode} handleChange={setPostalCode} style="col-span-4" />
							<label className="col-span-12">Contact Info(to contact with us)</label>
							<TextInput placeholder="Email address" value={email} handleChange={setEmail} style="col-span-4" />
							<TextInput placeholder="Kakaotalk ID" value={kakao} handleChange={setKakao} style="col-span-2" />
							<TextInput placeholder="Country Code" value={countryCode} handleChange={setCountryCode} style="col-span-2" />
							<TextInput placeholder="Phone Number" value={phone} handleChange={setPhone} style="col-span-4" />
							<textarea className="col-span-12 border-gray-200 border border-solid rounded p-4 h-48" placeholder="Note" />
						</div>
					</BaseBlock>
					<BaseBlock label="Pricing">
						<div>
							<RadioGroup<OptionRestType>
								label="Option Type"
								options={[
									{
										label: "POB Only",
										value: "POB",
										rest: {
											...serviceFees.POB,
											quantity: parseInt(rawQuantity),
											note: "We send you the Pre-Order Benefits at a discounted price + free shipping."
										}
									},
									{
										label: "POB + Inclusions",
										value: "POB_I",
										rest: {
											...serviceFees.POB_I,
											quantity: parseInt(rawQuantity),
											note: "We send you the Pre-Order Benefits and Inclusions for a cheaper shipping fee."
										}
									},
									{
										label: "Everything",
										value: "E",
										rest: {
											...serviceFees.E,
											quantity: parseInt(rawQuantity),
											note: "We send you everything included in your order(Albums, POB, Inclusions, etc.)"
										}
									}
								]}
								handleSelect={(v: string) => setOption(v)}
								selected={option}
								wrapper={<OptionWrapper />}
								containerStyle="grid grid-cols-3 gap-4 mt-4"
							/>
						</div>


					</BaseBlock>
					<BaseBlock label="Checkout">
						<div className="text-lg">
							{option == "E" ?
								<div>You pay for the product & service fee now. We will send you the shipping fee invoice when we receive the product.</div> :
								<div>
									One-time payment, nothing else to do until you receive your order!<br />
								</div>
							}
						</div>
					</BaseBlock>
					<div className="flex flex-row justify-end gap-4">
						{/* <div className="text-white bg-blue-600 px-4 py-2 rounded cursor-pointer" onClick={handleSubmit}>Checkout with Paypal</div> */}
						<button
							className="bg-white px-8 py-3 rounded-md border-2 border-gray-400"
							onClick={() => {
								handleSubmit(false);
							}}
						>
							Recieve Invoice by Email
						</button>
						<PayPalButton2 amount={round(getAmount(option), 2)} handleSubmit={handleSubmit} />
					</div>
				</div>

			</div>
		</PayPalScriptProvider>
	);
};

export default Form;