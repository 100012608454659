import { useEffect, useState } from "react";
import { handleSignInRedirect, handleSignInWithProvider } from "../server/auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Login = () => {

	const navigate = useNavigate();

	const [loading, setLoading] = useState(sessionStorage.getItem("loading") === "true");

	useEffect(() => {
		handleSignInRedirect().then(res => {
			if (res) {
				sessionStorage.removeItem("loading");
				navigate("/");
			}
		});
	}, []);


	return (
		<div className="w-full h-full flex flex-col items-center pb-96 pt-32">
			<div className="bg-white rounded border border-solid border-gray-300 px-8 py-6 mt-40 flex flex-col items-center">
				<div className="text-xl font-semibold mb-4">Sign In</div>
				{loading ? "loading..." : <SocialButton provider="google" label="Sign in with Google" color="white" icon="google" setLoading={setLoading} />}

			</div>
		</div>
	);
};

interface SocialButtonProps {
	provider: string
	label: string
	color: string
	textColor?: string
	borderColor?: string
	icon: string
	setLoading: (loading: boolean) => void
}

const SocialButton = ({ provider, label, color, textColor = "#3A3A3A", borderColor = "white", icon, setLoading }: SocialButtonProps) => {

	return (
		<div
			className={"relative w-80 h-12 border border-solid font-semibold rounded shadow my-2"}
			style={{
				backgroundColor: color,
				color: textColor,
				borderColor
			}}
		>
			<Helmet>
				<title>KPOPINME Proxy | Login</title>
				<meta name="description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:title" content="KPOPINME Proxy" />
				<meta property="og:description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:image" content="https://proxy.kpopin.me/images/logo.svg" />
			</Helmet>
			<button
				className="flex flex-row justify-between items-center px-3 py-3 w-80 h-12"
				onClick={() => {
					sessionStorage.setItem("loading", "true");
					setLoading(true);
					handleSignInWithProvider(provider);
				}}
			>
				<img className="mr-4" src={`icons/${icon}.png`} width={32} height={32} />
				<div className="absolute right-1/2 translate-x-1/2">{label}</div>
			</button>
		</div>
	);
};

export default Login; 