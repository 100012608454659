import { httpsCallable } from "firebase/functions";
import { functions } from "../server/firebase";

type ResponseData = {
	id: string
	[id: string]: string
}

interface PayPalButton2Props {
	amount: number
	handleSubmit: (isPayed: boolean) => void
}

const returnURL = process.env.NODE_ENV == "production" ?
	"https://www.paypal.com" :
	"https://www.sandbox.paypal.com";

const PayPalButton2 = ({ amount, handleSubmit }: PayPalButton2Props) => {

	const handlePayment = async () => {

		console.log(amount);

		if (amount <= 0 || !amount) {
			alert("Please enter a vaild price and quantity.");
			return;
		}

		const createOrder = httpsCallable(functions, "createOrder");

		try {
			const { data } = await createOrder({ amount: amount.toString() });
			console.log(data);
			handleSubmit(true);
			window.location.href = `${returnURL}/checkoutnow?token=${(data as ResponseData).id}`;
		} catch (e) {
			console.error(e);
			alert("An error occured in your payment process. However, your order has been submitted and we will send you an invoice via email. Please complete your purchase there.");
			handleSubmit(false);
		}
	};

	return (
		<button
			onClick={handlePayment}
			className="px-8 py-3 rounded-md bg-blue-500 text-white font-semibold"
		>
			Checkout with PayPal
		</button>
	);
};

export default PayPalButton2;