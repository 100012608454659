import TextInput from "./TextInput";

interface EditableTextProps {
	isEditing: boolean
	value: string
	placeholder: string
	handleChange: (v: string) => void
}

const EditableText = ({ isEditing, value, placeholder, handleChange }: EditableTextProps) => {
	return (
		isEditing ?
			<TextInput placeholder={placeholder} value={value} handleChange={handleChange} style="w-full" /> :
			<div className="px-[17px] py-3">{value}</div>
	);
};

export default EditableText;