import { ReactNode } from "react";

interface BaseBlockProps {
	label: string
	caption?: string
	children: ReactNode
}

const BaseBlock = ({ label, caption, children }: BaseBlockProps) => (
	<div className="w-full">
		<div className="font-semibold text-xl mb-4 ml-2 ">{label}</div>
		<div className="bg-white p-8 rounded-md flex flex-col gap-y-4 mb-4 border border-solid border-gray-200">
			{children}
		</div>
	</div>

);

export default BaseBlock;