const _calculateServiceFeeOption = (p: number, q: number, order: string, option: string, ex: number) => {
	const price = round(p / ex, 2);
	const productPrice = round(price * q, 2);
	const addFee = order == "E" ? 5 : 0;

	let perAlbumFee = 0;

	if (option == "POB") {
		perAlbumFee = -0.2 * q;
	} else if (option == "POB_I") {
		perAlbumFee = 1 * q;
	} else if (option == "E") {
		perAlbumFee = 0;
	}

	const tot = productPrice + addFee + perAlbumFee;

	return {
		price,
		productPrice,
		addFee,
		perAlbumFee,
		tot,
		shipping: estimateShippingFee(option, tot, q)
	};
};

export const calculateServiceFee = (p: number, q: number, order: string) => {
	return {
		POB: _calculateServiceFeeOption(p, q, order, "POB", 1200),
		POB_I: _calculateServiceFeeOption(p, q, order, "POB_I", 1200),
		E: _calculateServiceFeeOption(p, q, order, "E", 1200),
	};
};

const estimateShippingFee = (option: string, tot: number, q: number) => {

	const res = 0;

	if (option !== "E") {
		if (tot < 100) {
			return 10;
		} else {
			return 0;
		}
	} else {
		if (tot < 100) {
			return 10 + 5 * q;
		} else {
			return 5 * q;
		}
	}
};

export const round = (number: number, decimalPlaces: number) => {
	const factor = Math.pow(10, decimalPlaces);
	return Math.round(number * factor) / factor;
};

export const setDigits = (num: number, digits: number) => {
	return num.toString().padStart(digits, "0");
};

export const formatDate = (date: Date) => {
	return `${date.getFullYear()} - ${setDigits(date.getMonth() + 1, 2)} - ${setDigits(date.getDate(), 2)}`;
};

export const formatDateShort = (date: Date) => {
	return `${setDigits(date.getMonth() + 1, 2)}/${setDigits(date.getDate(), 2)}`;
};

export const parseRawDate = (rawDate: string) => {
	// YYMMDD
	const year = parseInt("20" + rawDate.slice(0, 2));
	const month = parseInt(rawDate.slice(2, 4)) - 1;
	const day = parseInt(rawDate.slice(4, 6));

	return new Date(year, month, day);
};

export const removeBlank = (raw: string) => {
	return raw.replace(/\s+/g, "");
};

export const getKST = (date: Date) => {

	// Convert to KST time (UTC+9)
	const kstOffset = 9 * 60; // KST is UTC+9
	const utc = date.getTime() + (date.getTimezoneOffset() * 60000); // Convert to UTC time
	const kstTime = new Date(utc + (kstOffset * 60000));

	// Extract and return date and time components
	return {
		year: kstTime.getUTCFullYear(),
		month: kstTime.getUTCMonth() + 1, // getUTCMonth() returns 0-11; add 1 for 1-12
		day: kstTime.getUTCDate(),
		hours: kstTime.getUTCHours(),
		minutes: kstTime.getUTCMinutes(),
		seconds: kstTime.getUTCSeconds()
	};
};

export const getDateFromKST = (year: number, month: number, day: number) => {
	// Note: month should be 0-indexed when passed into this function
	const kstOffset = 9 * 60 * 60000; // KST is UTC+9 in milliseconds
	const dateUtc = Date.UTC(year, month - 1, day, 23, 59, 59) - kstOffset;
	return new Date(dateUtc);
};

export const compareDate = (a: Date, b: Date) => {
	return a < b;
};