import { useEffect, useState } from "react";
import BaseBlock from "../components/BaseBlock";
import { EventData, fetchAllEvents, fetchAllArtists, fetchAllCompanies, EventType } from "../server/db";
import { formatDate, formatDateShort, getDateFromKST } from "../utils";
import Select from "react-select";
import { Helmet } from "react-helmet";


// TODO: automatically fill out form


const EventCalendar = () => {


	const [events, setEvents] = useState<EventData[]>([]);

	const [artists, setArtists] = useState<string[]>([]);
	const [companies, setCompanies] = useState<string[]>([]);

	const [artist, setArtist] = useState<string | undefined>(undefined);
	const [company, setCompany] = useState<string | undefined>(undefined);
	const [eventType, setEventType] = useState<EventType | undefined>(undefined);

	const [filteredEvents, setFilteredEvents] = useState<EventData[]>([]);

	useEffect(() => {

		const _setEvents = async () => {
			const fetchedEvents = await fetchAllEvents();
			setEvents(fetchedEvents);
			setFilteredEvents(fetchedEvents.filter(v => (getDateFromKST(v.endDate.getFullYear(), v.endDate.getMonth() + 1, v.endDate.getDate()) > new Date())));
		};

		const _setMetadata = async () => {
			const artists = await fetchAllArtists();
			const companies = await fetchAllCompanies();
			setArtists(artists);
			setCompanies(companies);
		};

		_setEvents();
		_setMetadata();

	}, []);


	const artistOptions = artists.map((v, i) => ({ value: i, label: v }));
	const companyOptions = companies.map((v, i) => ({ value: i, label: v }));
	const eventTypeOptions = [
		{ value: 1, label: "Luckydraw" },
		{ value: 2, label: "Fan Signing" },
		{ value: 3, label: "Video Call" },
		{ value: 4, label: "Pre Order" }
	];

	useEffect(() => {
		const newEvents = events.filter(v => (
			(!artist || artist == v.artist) &&
			(!company || company == v.company) &&
			(!eventType || eventType == v.eventType) &&
			(getDateFromKST(v.endDate.getFullYear(), v.endDate.getMonth() + 1, v.endDate.getDate()) > new Date())
		));
		setFilteredEvents(newEvents);

	}, [artist, company, eventType]);

	const eventEntryList = filteredEvents.map((v, i) => <EventEntry eventData={v} key={i} />);


	return (
		<div className="bg-[#fcfcfc]">
			<Helmet>
				<title>KPOPINME Proxy | Event Calendar</title>
				<meta name="description" content="KPOPINME Event Calendar is a calendar service that " />
				<meta property="og:title" content="KPOPINME Proxy" />
				<meta property="og:description" content="KPOPINME Proxy is a purchasing agent service tailored to international K-POP fans.
					We have optimized processes to help group orders, fan signing event participations, and all general orders.
					We also provide unpacking options to offer free shipping."/>
				<meta property="og:image" content="https://proxy.kpopin.me/images/logo.svg" />
			</Helmet>
			<div className="w-[920px] mx-auto py-20">
				<BaseBlock label="Filter">
					<div className="grid grid-cols-3 gap-4">
						<Select isClearable options={artistOptions} onChange={option => setArtist(option?.label)} placeholder="Select Artist" />
						<Select isClearable options={companyOptions} onChange={option => setCompany(option?.label)} placeholder="Select Company" />
						<Select isClearable options={eventTypeOptions} onChange={option => setEventType(option?.label as EventType)} placeholder="Select Event Type" />
					</div>

				</BaseBlock>
				<BaseBlock label="Event List">
					<div className="flex flex-col">

						<div className="grid grid-cols-12 font-bold bg-gray-100 px-4 py-3 gap-4">
							<div className="col-span-2">Artist</div>
							<div className="col-span-3">Event Name</div>
							<div className="col-span-2">Company</div>
							<div className="col-span-2">Apply Date</div>
							<div className="col-span-2">Event Date</div>
							<div className="col-span-1">Link</div>
						</div>
						{eventEntryList}

					</div>

				</BaseBlock>
			</div>
		</div>

	);
};



interface EventEntryProps {
	eventData: EventData
}

const EventEntry = ({ eventData }: EventEntryProps) => {

	const { name, eventType, artist, company, startDate, endDate, eventDate, link } = eventData;

	return (
		<div>
			<div className="grid grid-cols-12 col-span-1 px-4 py-2 gap-4 ">
				<div className="col-span-2">
					{artist}
				</div>
				<div className="col-span-3">
					[{name}] {eventType}
				</div>
				<div className="col-span-2">
					{company}
				</div>
				<div className="col-span-2">
					{formatDateShort(startDate)} ~ {formatDateShort(endDate)}
				</div>
				<div className="col-span-2">
					{formatDate(eventDate)}
				</div>
				<div>
					<a target="_blank" href={`${link}`} rel="noreferrer">
						<div>Click</div>
					</a>
				</div>
			</div>
			<div className="col-span-12 bg-gray-200 h-[1px]" />

		</div>

	);
};

export default EventCalendar;