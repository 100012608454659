import { User, onAuthStateChanged } from "firebase/auth";
import { ReactNode, createContext, useEffect, useState } from "react";
import { auth } from "../server/firebase";

interface UserContextType {
	user: User | null
}

export const UserContext = createContext<UserContextType>({ user: null });

interface UserProviderProps {
	children: ReactNode
}

const UserProvider = ({ children }: UserProviderProps) => {

	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, user => {
			setUser(user);
		});

		return unsubscribe;
	}, []);

	return (
		<UserContext.Provider value={{ user }}>
			{children}
		</UserContext.Provider>
	);
};

export default UserProvider;