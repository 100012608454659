interface TextInputProps {
	placeholder: string
	value: string
	handleChange: (v: string) => void
	style?: string
}

const TextInput = ({ placeholder, value, handleChange, style = "" }: TextInputProps) => {
	return (
		<input
			type="text"
			placeholder={placeholder}
			value={value}
			onChange={(e) => handleChange(e.target.value)}
			className={`h-12 px-4 rounded border bg-[#fcfcfc] border-gray-200 ${style}`}
		/>
	);
};

export default TextInput;