import { useState } from "react";

type FieldType = string

export interface ObjectData {
	[key: string]: FieldType
}

const useObjectState = (initialData: ObjectData) => {

	const [_data, _setData] = useState<ObjectData>(initialData);

	const setData = (newData: ObjectData) => {
		const tempData = { ..._data };
		Object.entries(newData).forEach(([key, value]) => tempData[key] = value);
		_setData(tempData);
	};

	const ret: [ObjectData, (newData: ObjectData) => void] = [_data, setData];

	return ret;
};

export default useObjectState;