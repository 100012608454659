import { round } from "../utils";
import { RadioButtonOption } from "./RadioGroup";

export interface OrderRestType {
	note: string
}

interface OrderWrapperProps {
	option?: RadioButtonOption<OrderRestType>
	selected?: string
}

export const OrderWrapper = ({ option, selected }: OrderWrapperProps) => {

	const { label, value, rest } = option || {};

	return (
		<div
			className={`border border-solid p-4 rounded w-64 flex flex-col gap-2
			${value == selected ? "text-blue-400 border-blue-400" : "text-gray-400 border-gray-200"}`}
		>
			<div className="font-semibold text-lg">
				{label}
			</div>
			<div>
				{rest?.note}
			</div>
		</div>
	);
};

export interface OptionRestType {
	note: string
	price: number
	quantity: number
	productPrice: number
	addFee: number
	perAlbumFee: number
	tot: number
	shipping: number
}

interface OptionWrapperProps {
	option?: RadioButtonOption<OptionRestType>
	selected?: string
}

export const OptionWrapper = ({ option, selected }: OptionWrapperProps) => {

	const { label, value, rest } = option || {};
	const { note, price, quantity, productPrice, addFee, perAlbumFee, tot, shipping } = rest || {};

	return (
		<div
			className={`border border-solid px-4 py-6 rounded-xl flex flex-col gap-2
			${value == selected ? "bg-blue-500 border-blue-500 text-white" : "text-gray-400 border-gray-200"}`}
		>
			<div className="font-semibold text-2xl ">
				{label}
			</div>
			<div className="h-16">
				{note}
			</div>
			<div className="flex flex-row gap-1 mt-6">
				$
				<div className="text-[4rem] -mt-6">
					{round((tot || 0) + (shipping || 0), 2)}
				</div>
			</div>
			<div className={`h-[1px] mb-4 mt-2 ${value == selected ? "bg-white" : "bg-gray-200"}`} />
			<div className="grid grid-cols-4 gap-x-4">
				<div className="col-span-3 flex flex-col gap-[2px]">
					<b>Product Price</b>
					<b>Total Product Price</b>
					<b>Additional Service Fee</b>
					<b>Album Handling Fee</b>
					<b>Estimated Shipping Fee</b>
				</div>
				<div className="col-span-1 flex flex-col gap-[2px]">
					<div>${price || 0}</div>
					<div>${productPrice || 0}</div>
					<div>{addFee ? "$" + addFee : <b>Free</b>}</div>
					<div>
						{perAlbumFee ? `$${Math.abs(perAlbumFee)}` : <b>Free</b>} <b>{(perAlbumFee || 0) < 0 ? "Discount" : ""}</b>
					</div>
					<div>{shipping ? "$" + shipping : <b>Free</b>}</div>
				</div>
			</div>
			<div className={`h-[1px] mt-4 mb-2 ${value == selected ? "bg-white" : "bg-gray-200"}`} />
			<div className="h-16 flex flex-row items-center ">
				{value == "E" ? "Pay for the product now, and we will send you the shipping fee invoice later." : "One-time payment."}
			</div>
		</div>
	);
};